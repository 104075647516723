import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { uri } from "../../../help/Api";

import HeaderD from "./A4/HeaderD"
import LoadingPage from "../../../services/LoadingPage";
import BodyInv from "./A4/BodyInv";

import UnderHeader from "./A4/UnderHeader"
import UnderHeaderT from "./A4/UnderHeaderT";
import * as _ from "lodash";
import { stElemnt } from "../../../St/Reviews/cStyle";
import { Grid, Typography } from "@material-ui/core";
import FooterCM from "./A4/FooterCM";
import { sto } from "../../../Context/InshData";
function BillPage(props) {
 // const { locInv, found, sta, inv, chunk } = props;
  const { locInv, found, sta, inv, chunk , isFirst , isLast, sum} = props;
  const premiApp = JSON.parse(localStorage.getItem("premiApp")) || {};

  return (
    <Grid item container style={{ ...stElemnt.AF.Reviws.pdf_page }}>

    <Fragment>
      {premiApp.billTemplate !== 9?(
    <Grid xs={12} item  
    style={{ ...stElemnt.AF.Reviws.pdf_bodyCom }}
    >
      <HeaderD locInv={locInv} foundData={found} sta={sta} />
      <UnderHeaderT found={found} inv={inv} sta={sta}/>
      <UnderHeader found={found} inv={inv} sta={sta}  locInv={locInv}/>
      <BodyInv 
       data={chunk ?? []}
       isFullPage={!isFirst ? true : false}
       isLast={isLast}
       inv={inv}
      />
     </Grid>
      ):(
        <Grid xs={12} item  
        style={{ ...stElemnt.AF.Reviws.pdf_bodyComany }}
        >
          <HeaderD locInv={locInv} foundData={found} sta={sta} />
          <UnderHeaderT found={found} inv={inv} sta={sta}/>
          <UnderHeader found={found} inv={inv} sta={sta}  locInv={locInv}/>
          <BodyInv 
           data={chunk ?? []}
           isFullPage={!isFirst ? true : false}
           isLast={isLast}
           inv={inv}
          />
         </Grid>
      )}

     <Grid xs={12} item  style={{ maxHeight:"3.8cm" ,minHeight:"3.8cm"}}>
      <FooterCM found={found} inv={inv} data={sta ?? {}} />
      </Grid>
    </Fragment>
    </Grid>
  );
}
function InvContainer(props) {
  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};
  const [found, setFound] = useState({});
  const [sta, setSta] = useState({});
  const [inv, setInv] = useState({});
  const [loading, setLoading] = useState(true);
  const [chunks, setChunks] = useState([]);
  const [chunks2, setChunks2] = useState([]);

  const getInfo = async () => {
    // alert('getInfo')
    const tb = {
      GetFoundation: true,
      Rpt_Inv: true,
      wait: true,
      parms: {
        lprocid: locInv.proc_id,
        ibranchid: sto.branchi,
        iFoundid: 1,
        branch:sto.branchi,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
      },
      autos: {},
    };
    console.log(tb);
    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setSta({ info: res.data.RptInv, tot: res.data.infoBill });
        console.log("res.data.RptInv");
        setChunks(_.chunk(res.data.RptInv, 8));
        setChunks2(res.data.RptInv);

        setFound(res.data.Found);
      })
      .catch((error) => {
        console.log("catch");
      });
  };
  const getInv = async () => {
    axios
      .get(uri + `InvForFatora?proc_id=${locInv.proc_id}`)
      .then((res) => {
        console.log("the inve for fatoora", res.data);
        setInv(res.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    setTimeout(async () => {
      await getInfo();
      await getInv();
      setLoading(false);
      // onUnmountComplete();
    }, 0);
  }, []);

  if (!found || !sta || loading) return <LoadingPage />;

  // return (
  //   <Fragment>
  //     {chunks.map((page, i) => {
  //       return <BillPage {...{ locInv, found, sta, inv, chunk: page }} />;
  //     })}
  //   </Fragment>
  // );

  return (
    <Typography component="div" style={stElemnt.AF.Reviws.ReviwsJard}>
      
      {/* <Typography component="div" style={{ ...stElemnt.AF.Reviws.pdf_page }}> */}
      <Typography component="div" >
        {chunks.map((page, i) => {
          return (
            <BillPage
              {...{
                locInv,
                found,
                sta, 
               inv, 
                chunk: page,
                isFirst: i == 0,
                isLast: i == chunks.length - 1,
              }}
            />
          );
        })}
      </Typography>
    </Typography>
  );
}
export default InvContainer;
