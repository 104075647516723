import React, { useEffect, useState, Fragment } from "react";
// import { makeStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import axios from "axios";
import { uri } from "../../help/Api";
import { insh } from "../../Context/InshData";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import ReceiptIcon from "@material-ui/icons/Receipt";
// import BluetoothIcon from '@material-ui/icons/Bluetooth';
import Grid from "@material-ui/core/Grid";
import Swipeable from "../../Fronts/Main/Swipeable";
// import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import { useStylesSetting } from "../../St/comps/UseStyle";
import { Button, Divider, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Star } from "@material-ui/icons";
import useFetchURL from "../../components/Hooks/useFetchURL";
import { ToastBar, Toaster } from "react-hot-toast";
import LoadingPage from "../../services/LoadingPage";
const cashirPrintType = [
  { id: 1, name: "افتراضي" },
  { id: 2, name: "مبسط" },
  { id: 3, name: "مطعم انجليزي" },
];
const billPrintType = [
  { id: 1, name: "افتراضي" },
  { id: 2, name: "مبسط" },
  { id: 3, name: "Gold Bill" },
  { id: 4, name: "Template 4" },
  { id: 5, name: "Company Inv" },
   { id: 6, name: "Maintaince Inv" },
   { id: 7, name: "مبسط2" },
   { id: 8, name: "مبسط3" },
   { id: 9, name: "Company2" },
   { id: 10, name: "EnglishInv" },
   { id: 11, name: "Default2" },
   { id: 12, name: "Default3" },

];
export function SubPrintCasher(props) {
  const { hasService, printers } = props;
  const clasStt = useStylesSetting();
  const [showSc, setShowSc] = useState(false);
  const [permissionApp, setPermissionApp] = useState(insh.PermissionApp);
  let pApp = JSON.parse(localStorage.getItem("premiApp"));
  const [cashirWidth, setCashirWidth] = useState(pApp.cachirWidth);
  const [deviceName, setDeviceName] = useState(pApp?.deviceName);
  const [cashirTemplate, setCashirTemplate] = useState(() => {
    let index = 0;

    if (pApp.cashirTemplate) index = pApp.cashirTemplate - 1;
    return cashirPrintType[index];
  });

  const [billTemplate, setBillTemplate] = useState(() => {
    let index = 0;

    if (pApp.billTemplate) index = pApp.billTemplate - 1;
    return billPrintType[index];
  });
  const [printer, setPrinter] = useState(pApp.printerName);

  const storgeing = async (info) => {
    const tb = {
      PermissionApp: true,
      wait: true,
      /*params*/
      parms: {
        iuserid: info.user_id,
      },
      autos: {},
    };
    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        console.log("the res is", res);
        localStorage.setItem("premiApp", JSON.stringify(res.data.permisApp));
        setPermissionApp(res.data.permisApp);
        setShowSc(res.data.wait.state);
      })
      .catch((error) => {
        // console.log('nooooo sttorgeing');
      });
  };

  const changeWidth = async (e) => {
    setCashirWidth(e.target.value);
  };

  const [submitting, setSubmitting] = useState(false);
  const saveChangesHandle = async () => {
    setSubmitting(true);
    let perApp = {
      ...permissionApp,
      cachirWidth: cashirWidth,
      cashirTemplate: cashirTemplate.id,
      billTemplate: billTemplate.id,
      deviceName: deviceName,
      printerName: printer,
    };
    console.log(perApp);
    await axios
      .put(uri + "PermissionApp", perApp)
      .then((res) => {
        storgeing(res.data);
      })
      .catch((error) => {
        alert("لم يتم حفظ التعديلات");
      });
    setSubmitting(false);
  };

  const updatePrem = async (prop, event) => {
    if (prop == "stayWCa") permissionApp.stayWCa = event.target.checked;
    if (prop == "hidePayType") permissionApp.hidePayType = event.target.checked;
    if (prop == "tobaccoFees") permissionApp.tobaccoFees = event.target.checked;
    if (prop == "useElectronicScale")
      permissionApp.useElectronicScale = event.target.checked;

    await axios
      .put(uri + "PermissionApp", permissionApp)
      .then((res) => {
        setShowSc(true);
        storgeing(res.data);
      })
      .catch((error) => {});
  };

  return (
    <Fragment>
      <Toaster />
      {/* <div>{showSc+""}</div> */}
      <Container maxWidth="md" style={{ paddingBottom: "120px" }}>
        <Typography component="div" style={{ backgroundColor: "#f5f5f5b5" }}>
          <Grid container md={12} xs={12}className={clasStt.rootSett}>
            <List className={clasStt.rootSett}>
              <ListItem>
                <ListItemIcon>
                  {" "}
                  <ReceiptIcon />{" "}
                </ListItemIcon>
                <ListItemText>قالب الفاتورة الكاشير</ListItemText>
                <ListItemSecondaryAction style={{ width: "200px" }}>
                  <Autocomplete
                    value={cashirTemplate}
                    fullWidth
                    onChange={(event, newValue) => {
                      setCashirTemplate(newValue);
                    }}
                    id="combo-box-demo"
                    size="small"
                    options={cashirPrintType}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    style={{}}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="نوع الفاتورة"
                        variant="outlined"
                      />
                    )}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem style={{ paddingTop: "1rem" }}>
                <ListItemIcon>
                  {" "}
                  <ReceiptIcon />{" "}
                </ListItemIcon>
                <Typography>"تغيير عرض فاتورة الكاشير"</Typography>
                <ListItemSecondaryAction>
                  <Grid item lg={6} md={6} sm={3} xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="dateFrom"
                      label=" "
                      style={{ width: "100px" }}
                      type="number"
                      name="dateFrom"
                      value={cashirWidth}
                      onChange={changeWidth}
                    />
                  </Grid>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LineStyleIcon />
                </ListItemIcon>
                {permissionApp.stayWCa === true ? (
                  <ListItemText
                    id="switch-list-label-wi"
                    className={clasStt.itemText}
                    secondary="تم الإخفاء"
                    primary="  ظهور وإخفاء بيانات العميل كاشير"
                  />
                ) : (
                  <ListItemText
                    id="switch-list-label-w"
                    className={clasStt.itemText}
                    secondary="قم بالتشغيل لإخفاء البيانات"
                    primary="  ظهور وإخفاء بيانات العميل كاشير"
                  />
                )}
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={(e) => updatePrem("stayWCa", e)}
                    checked={permissionApp.stayWCa}
                    inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
                    name="curr"
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LineStyleIcon />
                </ListItemIcon>
                {permissionApp.hidePayType === true ? (
                  <ListItemText
                    id="switch-list-label-wi"
                    className={clasStt.itemText}
                    secondary="تم الإخفاء"
                    primary="ظهور واخفاء نوع الفاتورة في المبسط"
                  />
                ) : (
                  <ListItemText
                    id="switch-list-label-w"
                    className={clasStt.itemText}
                    secondary="قم بالتشغيل لإخفاء البيانات"
                    primary="ظهور واخفاء نوع الفاتورة في المبسط"
                  />
                )}
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={(e) => updatePrem("hidePayType", e)}
                    checked={permissionApp.hidePayType}
                    inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
                    name="curr"
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LineStyleIcon />
                </ListItemIcon>
                {permissionApp.tobaccoFees === true ? (
                  <ListItemText
                    id="switch-list-label-wi"
                    className={clasStt.itemText}
                    secondary="تم الإخفاء"
                    primary="ظهور واخفاء رسوم التبغ في فاتورة المطعم"
                  />
                ) : (
                  <ListItemText
                    id="switch-list-label-w"
                    className={clasStt.itemText}
                    secondary="قم بالتشغيل لإخفاء البيانات"
                    primary="ظهور واخفاء رسوم التبغ في فاتورة المطعم"
                  />
                )}
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={(e) => updatePrem("tobaccoFees", e)}
                    checked={permissionApp.tobaccoFees}
                    inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
                    name="curr"
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LineStyleIcon />
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-w"
                  className={clasStt.itemText}
                  primary="اسم جهاز المستخدم لتفعيل الطباعة الصامتة"
                  secondary="لتفعيل هذا الاعداد يتطلب قبل ذلك تشغيل orax service print في جهازم العميل"
                />
                <ListItemSecondaryAction>
                  <Grid item lg={6} md={6} sm={3} xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="deviceName"
                      label="اسم الجهاز"
                      style={{ width: "200px" }}
                      type="text"
                      name="deviceName"
                      value={deviceName}
                      onChange={(e) => {
                        setDeviceName(e.target.value);
                      }}
                    />
                  </Grid>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LineStyleIcon />
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-w"
                  className={clasStt.itemText}
                  primary="اسم  الطابعة الافتراضية للطباعة الصامتة"
                  // secondary="لتفعيل هذا الاعداد يتطلب قبل ذلك تشغيل orax service print في جهازم العميل"
                />
                <ListItemSecondaryAction style={{ width: "200px" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    id="printerName"
                    label="اسم الطابعة"
                    style={{ width: "200px" }}
                    type="text"
                    name="printerName"
                    value={printer}
                    onChange={(e) => {
                      setPrinter(e.target.value);
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              {!hasService && (
                <ListItem>
                  <ListItemText
                    id="switch-list-label-w"
                    className={clasStt.itemText}
                    style={{ color: "#f00", fontSize: 40 }}
                    primary="خدمة الطباعة غير موجودة انها لا تعمل في هذا الجهاز"
                  />
                </ListItem>
              )}
            </List>
          </Grid>

          <br />
          <Divider />

          <Grid container md={12} className={clasStt.rootSett}>
            <List className={clasStt.rootSett}>
              <ListItem>
                <ListItemIcon>
                  {" "}
                  <ReceiptIcon />{" "}
                </ListItemIcon>
                <ListItemText>قالب فاتورة كبير </ListItemText>
                <ListItemSecondaryAction style={{ width: "200px" }}>
                  <Autocomplete
                    value={billTemplate}
                    fullWidth
                    onChange={(event, newValue) => {
                      setBillTemplate(newValue);
                    }}
                    id="combo-box-demo"
                    size="small"
                    options={billPrintType}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    style={{}}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="نوع الفاتورة"
                        variant="outlined"
                      />
                    )}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LineStyleIcon />
                </ListItemIcon>
                {permissionApp.useElectronicScale === true ? (
                  <ListItemText
                    id="useElectronicScale"
                    className={clasStt.itemText}
                    secondary=" تم التشغيل"
                    primary="استخدام الميزان الالكتروني"
                  />
                ) : (
                  <ListItemText
                    id="useElectronicScale"
                    className={clasStt.itemText}
                    secondary="قم  الايقاف "
                    primary="استخدام الميزان الالكتروني"
                  />
                )}
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={(e) => updatePrem("useElectronicScale", e)}
                    checked={permissionApp.useElectronicScale}
                    inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
                    name="useElectronicScale"
                  />
                </ListItemSecondaryAction>
              </ListItem>
              {/* <ListItem style={{ paddingTop: "1rem" }}>
                <ListItemIcon>
                  {" "}
                  <ReceiptIcon />{" "}
                </ListItemIcon>
                <Typography>"تغيير ارتفاع ترويسة فاتورة كبير"</Typography>
                <ListItemSecondaryAction>
                  <Grid item lg={6} md={6} sm={3} xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="dateFrom"
                      label=" "
                      style={{ width: "100px" }}
                      type="number"
                      name="dateFrom"
                      value={cashirWidth}
                      onChange={changeWidth}
                    />
                  </Grid>
                </ListItemSecondaryAction>
              </ListItem> */}
            </List>
          </Grid>
          <Typography
            component="div"
            style={{ margin: "auto", alignItems: "center" }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={saveChangesHandle}
              disabled={submitting}
              // style={{...style.iconBtn,...stElemnt.all.iconBtn}} //disabled={true}
              // onClick={() => { setChecked({...checked,autoOrStaticck:true}); handleDia(TransitionUp,'fav',true)}}
            >
              {" "}
              {"حفظ التعديلات"}
            </Button>
          </Typography>
        </Typography>
      </Container>
    </Fragment>
  );
}

export default function PrintCasher() {
  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url: "http://localhost:7000/printers/list",
      mapFunction: (item) => {
        const res = [];
        let index = 0;
        for (let frm of item) {
          res.push({ id: index++, name: frm });
        }
        return res;
      },
    },
  });
  useEffect(() => {
    console.log("printers is", data);
  });
  if (fetching) {
    return <LoadingPage />;
  }
  return (
    <Fragment>
      <SubPrintCasher printers={data} hasService={data?.length > 0} />
      <Typography component="div">
        <Swipeable />
      </Typography>
    </Fragment>
  );
}
